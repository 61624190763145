/**
 * @typedef Object CustomAttributes
 * Arbitrary property-value map to provide additional information to datadog
 * TODO: rename this service module, it no longer uses newrelic
 * @type {Object.<string, string>}
 */

function NewRelic ($window, $log) {
    /**
     * Logs the provided error to newrelic along with any provided custom attributes,
     * then re-throws the error
     * @method logAndRethrow
     * @param {Error} error
     * @param {string} module
     * @param {string} method
     * @param {CustomAttributes} [customAttributes]
     */
    this.logAndRethrow = function (error, module, method, customAttributes) {
        this.noticeError(error, module, method, customAttributes);
        throw error;
    };

    /**
     * Captures a page action in NR.
     *
     * @public
     * @method addPageAction
     * @param {string} actionName name of the page action
     * @param {CustomAttributes} [customAttributes]
     */
    this.addPageAction = function (actionName, customAttributes) {
        if ($window.DD_LOGS) {
            $window.DD_LOGS.logger.info(actionName, customAttributes);
        }
    };

    /**
     * Submits an error report to new relic
     * @method noticeError
     * @param {Error} error
     * @param {string} module
     * @param {string} [method]
     * @param {CustomAttributes} [customAttributes]
     */
    this.noticeError = function (error, module, method, customAttributes) {
        if ($window.DD_LOGS) {
            customAttributes = customAttributes || {};
            customAttributes.module = module;
            customAttributes.method = method;
            if(error && error.message) {
                $window.DD_LOGS.logger.error(error.message, customAttributes, error);
            } else {
                $window.DD_LOGS.logger.error(error, customAttributes);
            }
        } else {
            $log.warn(error);
        }
    };

    /**
     * Sets the custom event attribute as a custom attribute to new relic
     * @param {string} customEventAttribubteKey
     * @param {object} customEventAttribubteValue
     */
    this.setCustomEventAttribute = function (customEventAttribubteKey, customEventAttribubteValue) {
        if ($window.DD_LOGS) {
            $window.DD_LOGS.setUserProperty(customEventAttribubteKey, customEventAttribubteValue);
        }
    };

}

module.exports = function (app) {
    app.service('NewRelic', NewRelic);
};
